// 车辆报警
<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent"
            @on-click="onClickTabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Select v-model="selectType"
                    placeholder="查询分类"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in selectTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <!-- <template v-if="currentTabIndex===0">
              <Select v-model="departmentType"
                      placeholder="机构类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in departmentTypeArr"
                        :value="item.name"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            search
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
            </template> -->
            <template v-if="currentTabIndex===2">
              <!-- <MyTreeSelect :value="name"
                            placeholder="姓名"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            @onSelectChange="onChangeName">
              </MyTreeSelect> -->
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteName"
                      filterable
                      clearable
                      :remote-method="remoteNameMethod"
                      :loading="remoteNameLoading"
                      placeholder="请输入姓名"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteName">
                <Option v-for="(option, index) in remoteNameList"
                        :value="option.name"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <Select v-model="personAlarmType"
                      placeholder="报警类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in personAlarmTypeArr"
                        :value="item.name"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===0">
              <!-- <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            :data="carTreeArr"
                            search
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeCarNumber">
              </MyTreeSelect> -->
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      placeholder="请输入车牌号"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.name"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <Select v-model="alarmType"
                      placeholder="报警类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in alarmTypeArr"
                        :value="item.name"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===1">
              <!-- <MyTreeSelect :value="facilityName"
                            placeholder="设施名称"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="facilityArr"
                            search
                            @onSelectChange="onChangeFacility">
              </MyTreeSelect> -->
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteFacl"
                      filterable
                      clearable
                      :remote-method="remoteFaclMethod"
                      :loading="remoteFaclLoading"
                      placeholder="请输入设施名称"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteFacl">
                <Option v-for="(option, index) in remoteFaclList"
                        :value="option.name"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <Select v-model="facilityType"
                      clearable
                      placeholder="设施类型"
                      class="m-r-10"
                      style="width:160px"
                      @on-change="onChangeBigType">
                <Option v-for="item in facilityTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="alarmTypes"
                      clearable
                      placeholder="报警类型"
                      class="m-r-10"
                      style="width:160px"
                      :disabled="!facilityType">
                <Option v-for="item in alarmTypeArrs"
                        :value="item.name"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        ref="formDate"
                        :clearable='false'
                        :editable='false'
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        :options="dateOptions"
                        style="width:260px"
                        v-show="selectType===1"></DatePicker>
            <!-- <DatePicker :value="dateMonth"
                        type="month"
                        placeholder="选择月份"
                        @on-change="onChangeDate"
                        style="width:160px"
                        v-if="selectType===2"></DatePicker> -->
            <Monthrange :itemStyle="{width:'160px','margin-right':'10px'}"
                        :dateMonthrange="dateMonthrange"
                        @onChange="onChangeMonthrange"
                        v-show="selectType===2"></Monthrange>
          </div>
          <div class="flex">
            <template v-if="selectType===1">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/policeReport/query'])"
                      :ghost="buttonIndex == 2 ? false : true"
                      @click.stop="onClickNearlyThreeDay">近三天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/policeReport/query'])"
                      :ghost="buttonIndex == 0 ? false : true"
                      @click.stop="onClickNearlySevenDay">近七天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/policeReport/query'])"
                      :ghost="buttonIndex == 1 ? false : true"
                      @click.stop="onClickCurrentMonth">本月</Button>
            </template>
            <template v-if="selectType===2">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/policeReport/query'])"
                      :ghost="buttonIndex == 3 ? false : true"
                      @click.stop="onClickPreMonthDay(3)">近三月</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/policeReport/query'])"
                      :ghost="buttonIndex == 4 ? false : true"
                      @click.stop="onClickPreMonthDay(6)">近六月</Button>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/policeReport/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/policeReport/query'])"
                    @click.stop="onResetSearchData">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="section">
        <Tabs v-model="currentEchartsTabIndex"
              class="tabs"
              key="childEcharts4">
          <TabPane v-for="item in echartsTabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
        </Tabs>
        <Echarts height="400px"
                 :options="totalOptions"
                 :data="getEchartsData"
                 v-show="getEchartsData.length"></Echarts>
        <div class="noData"
             v-show="getEchartsData.length===0">
          <img src="@/assets/common/tubiaono.png">
          暂无数据
        </div>
      </div>
      <div class="section">
        <div class="flex a-center j-between">
          <!-- <template v-if="currentTabIndex!==0"> -->
          <Tabs v-model="currentAlarmTabIndex"
                class="tabs"
                key="child1">
            <TabPane v-for="item in alarmTabArr"
                     :key="item.name"
                     :label="item.name"
                     :disabled="currentAlarmTabIndex==0"></TabPane>
          </Tabs>
          <Dropdown v-if="checkPermi(['/admin/policeReport/export'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="part">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('part')">
                  <Button>
                    导出部分
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <!-- </template> -->
        <div class="table"
             :class="currentTabIndex===0?'p-t-15':''">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="getData"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import MyTreeSelect from '@/components/common/MyTreeSelect.vue'
import { mapActions, mapGetters } from 'vuex';
import { getToday, getYesterday, getNearlyThreeDay, getCurrentMonth, getLastMonth, getYearOrMonthForMonth, getPreMonthDay, getCurrentWeek, formatDate, getNearlySevenDay, getCurrentYearToNow } from '@/utils/dateRangUtil'
import Monthrange from '@/components/common/Monthrange'
import ExportTemplate from '@/components/common/ExportTemplate'
import { throttle } from '@/utils/debounceAndThrottle';
import { checkPermi } from '@/utils/permission'
export default {
  components: { Echarts, MyTreeSelect, Monthrange, ExportTemplate },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      deptAlarmEcharsData: [],  // 机构报警 echars 数据
      carAlarmEcharsData: [], // 车辆报警 echars 数据
      personAlarmEcharsData: [], // 人员报警 echars 数据
      facilitiesAlarmEcharsData: [], // 设备报警 echars 数据
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        // {
        //   name: '机构报警'
        // },
        {
          name: '车辆报警'
        },
        {
          name: '设施报警'
        },
        {
          name: '人员报警'
        },
      ],
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      departmentType: '',
      departmentTypeArr: [
        {
          id: 1,
          name: '全部'
        },
        {
          id: 2,
          name: '项目'
        },
        {
          id: 3,
          name: '标段'
        },
        {
          id: 4,
          name: '部门'
        },
        {
          id: 5,
          name: '班组'
        },
      ],
      departmentName: '',
      departmentId: '',
      name: '',
      nameId: '',
      remoteName: '',
      remoteNameList: [],
      remoteNameLoading: false,
      carNumber: '',
      carNumberId: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      facilityType: '',
      facilityTypeArr: [
        {
          id: 0,
          name: '全部'
        },
      ],
      facilityName: '',
      facilityId: '',
      remoteFacl: '',
      remoteFaclList: [],
      remoteFaclLoading: false,
      alarmType: '',
      faclAlarmTypeDisabled: true,
      alarmTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '离线'
        },
        {
          id: 2,
          name: '漏油'
        },
        {
          id: 3,
          name: '超速'
        },
      ],
      personAlarmType: '',
      personAlarmTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '离线'
        },
        {
          id: 2,
          name: 'SOS求救'
        },
      ],
      alarmTypes: '',
      alarmTypeArrs: [
        {
          id: 0,
          name: '全部'
        },
      ],
      date: [],
      dateMonthrange: [],
      dateMonth: '',
      currentEchartsTabIndex: 0,
      echartsTabArr: [
        {
          name: '报警分析'
        },
      ],
      currentAlarmTabIndex: 0,
      alarmTabArr: [
        {
          name: '报警汇总'
        },
        {
          name: '报警明细'
        },
      ],
      totalOptions: {
        dataset: {
          source: []
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          name: '单位：次'
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderWidth: '1',
          borderColor: 'gray',
          textStyle: {
            color: 'black'
          },
          formatter: '',
        },
        series: [{
          type: 'line',
          // smooth: true,
          barWidth: 30,
        }],
        color: ['#25bb96'],
      },
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      dataDepartment: [],
      columnsDepartment: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'departName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '机构类型',
          key: 'orgType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计报警(次)',
          key: 'allAlarmCount',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '人员报警(次)',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '车辆报警(次)',
          key: 'carAlarmCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施报警(次)',
          key: 'sheshiAlarmCount',
          tooltip: true,
          align: 'center'
        },
      ],
      dataPersonSummary: [],
      columnsPersonSummary: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '人员姓名',
          key: 'personName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickPersonAlarmDetail(row)
                }
              }
            }, row.personName);
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计报警(次)',
          key: 'allCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '离线(次)',
          key: 'lxCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: 'SOS求救(次)',
          key: 'soscount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
      ],
      dataPersonDetail: [],
      columnsPersonDetail: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '人员姓名',
          key: 'personName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '人员工种',
          key: 'personTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警类型',
          key: 'alarmTypeName',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '设备低电',
        //   key: 'managePhone',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '开始时间',
          key: 'alarmStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束时间',
          key: 'alarmEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警时长',
          key: 'content',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警位置',
          key: 'address',
          tooltip: true,
          align: 'center'
        },
      ],
      dataCarSummary: [],
      columnsCarSummary: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carName',
          tooltip: true,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickCarAlarmDetail(row)
                }
              }
            }, row.carName);
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计报警(次)',
          key: 'allCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '离线(次)',
          key: 'lxCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '漏油(次)',
          key: 'lyCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '超速(次)',
          key: 'csCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
      ],
      dataCarDetail: [],
      columnsCarDetail: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警类型',
          key: 'alarmTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始时间',
          key: 'alarmStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束时间',
          key: 'alarmEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警时长',
          key: 'content',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警位置',
          key: 'address',
          tooltip: true,
          align: 'center'
        },
      ],
      dataFacilitySummary: [],
      columnsFacilitySummary: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'sheshiName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickFaclAlarmDetail(row)
                }
              }
            }, row.sheshiName);
          }
        },
        {
          title: '设施类型',
          key: 'sheshiType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计报警(次)',
          key: 'allCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '硫化氢报警(次)',
          key: 'h2SCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '电耗报警(次)',
          key: 'consumePowerCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '烟雾报警(次)',
          key: 'smokeCount',
          tooltip: true,
          align: 'center',
          sortable: true
        },
        {
          title: '氨气报警(次)',
          key: 'nh4Count',
          tooltip: true,
          align: 'center',
          sortable: true
        },
      ],
      dataFacilityDetail: [],
      columnsFacilityDetail: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'facilitiesName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '设施类型',
          key: 'facltypename',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警类型',
          key: 'alarmTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始时间',
          key: 'alarmStartTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束时间',
          key: 'alarmEndTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警时长',
          key: 'content',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警位置',
          key: 'address',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      facilityArr: [],
      tadayArr: null
    };
  },
  watch: {
    selectType (newVal) {
      if (newVal == 1) {
        this.onClickCurrentMonth()
      } else {
        this.onClickCurrentYear()
      }
    },
    currentAlarmTabIndex (newVal) {
      this.selectArr = []
      if (newVal == 0) {
        this.carNumber = ''
        this.carNumberId = ''
        this.remoteCar = ''
        this.nameId = ''
        this.name = ''
        this.remoteName = ''
        this.facilityId = ''
        this.facilityName = ''
        this.remoteFacl = ''
      }
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            if (this.currentAlarmTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.carName == items.carName
              })
              this.selectIndexArr.push(data.carName)
            }
            if (this.currentAlarmTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.alarmStartTime == items.alarmStartTime
              })
              this.selectIndexArr.push(data.alarmStartTime)
            }

          } if (this.currentTabIndex === 1) {
            if (this.currentAlarmTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.sheshiId == items.sheshiId
              })
              this.selectIndexArr.push(data.sheshiId)
            }
            if (this.currentAlarmTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.alarmStartTime == items.alarmStartTime
              })
              this.selectIndexArr.push(data.alarmStartTime)
            }

          }
          if (this.currentTabIndex === 2) {
            if (this.currentAlarmTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.personId == items.personId
              })
              this.selectIndexArr.push(data.personId)
            }
            if (this.currentAlarmTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.alarmStartTime == items.alarmStartTime
              })
              this.selectIndexArr.push(data.alarmStartTime)
            }

          }
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'personTreeArr': 'getPersonTreeList',
      'carTreeArr': 'getCarTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        // case 0:
        //   columns = this.columnsDepartment
        //   break;
        case 0:
          switch (this.currentAlarmTabIndex) {
            case 0:
              columns = this.columnsCarSummary
              break;
            case 1:
              columns = this.columnsCarDetail
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentAlarmTabIndex) {
            case 0:
              columns = this.columnsFacilitySummary
              break;
            case 1:
              columns = this.columnsFacilityDetail
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentAlarmTabIndex) {
            case 0:
              columns = this.columnsPersonSummary
              break;
            case 1:
              columns = this.columnsPersonDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getData () {
      let columns = []
      switch (this.currentTabIndex) {
        // case 0:
        //   columns = this.dataDepartment
        //   break;
        case 0:
          switch (this.currentAlarmTabIndex) {
            case 0:
              columns = this.dataCarSummary
              break;
            case 1:
              columns = this.dataCarDetail
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentAlarmTabIndex) {
            case 0:
              columns = this.dataFacilitySummary
              break;
            case 1:
              columns = this.dataFacilityDetail
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentAlarmTabIndex) {
            case 0:
              columns = this.dataPersonSummary
              break;
            case 1:
              columns = this.dataPersonDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getEchartsData () {
      let columns = []
      switch (this.currentTabIndex) {
        // case 0:
        //   columns = this.deptAlarmEcharsData
        //   break;
        case 0:
          columns = this.carAlarmEcharsData
          let asd = asd
          break;
        case 1:
          columns = this.facilitiesAlarmEcharsData
          break;
        case 2:
          columns = this.personAlarmEcharsData
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentAlarmTabIndex) {
            case 0:
              path = this.$http.exportCarAlarmReportForm
              break;
            case 1:
              path = this.$http.exportCarAlarmDetailReportForm
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentAlarmTabIndex) {
            case 0:
              path = this.$http.exportFaclAlarmReportForm
              break;
            case 1:
              path = this.$http.exportFaclAlarmDetailReportForm
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentAlarmTabIndex) {
            case 0:
              path = this.$http.exportPersonAlarmReportForm
              break;
            case 1:
              path = this.$http.exportPersonAlarmDetailReportForm
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updatePersonTreeList',
      'updateCarTreeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDepartmentTreeList()
      this.updatePersonTreeList()
      this.updateCarTreeList()
      this.getFacilityList()
      this.getFacilitiesAlarmSelect()
      this.onChangeBigType()
      this.todayArr = getToday().split('-')
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 机构报警数据
    getDeptAlarmData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        id: this.departmentId,  // 机构Id
        orgType: this.departmentType, // 机构类型 
      }
      // 时间
      this.dateJudge(params, 'dept')
      this.getDeptAlarmEchars(params)
      this.getDeptAlarmList(params)
    },
    // 机构报警 报警分析 echars 数据
    getDeptAlarmEchars (params) {
      this.$http.getDeptAlarmEchars(params).then(res => {
        if (res.code == 200) {
          this.deptAlarmEcharsData = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
              <div >
                全部: ${e[0].data[1]}<br />
                <span style="color:#ff1a1a">人员报警: </span> ${e[0].data[2]}<br />
                <span style="color:#fe9400">车辆报警: </span>: </span>${e[0].data[3]}<br />
                <span style="color:#0079fe">设施报警: </span>${e[0].data[4]}<br />
              </div>
            `
          }
        }
      })
    },
    // 机构报警 列表 
    getDeptAlarmList (params) {
      this.tableLoading = true
      this.$http.getDeptAlarmList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataDepartment = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 车辆报警数据
    getCarAlarmData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        carName: this.remoteCar ? this.remoteCar.split('[')[0] : '',  // 车牌号       
        alarmTypeName: this.alarmType == '全部' ? '' : this.alarmType, // 报警类型 
        depId: this.departmentId
      }
      // 时间
      this.dateJudge(params, 'car')
      this.getCarAlarmEchars(params)
      switch (this.currentAlarmTabIndex) {
        case 0:
          this.getCarAlarmList(params)
          break;
        case 1:
          this.getCarAlarmDetailList(params)
          break;
        default:
          break;
      }
    },
    // 车辆报警 报警分析 echars 数据
    getCarAlarmEchars (params) {
      // 天1 月2
      params.dateType = this.selectType
      this.$http.getCarAlarmEchars(params).then(res => {
        if (res.code == 200) {
          this.carAlarmEcharsData = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
              <div >
                ${e[0].data[0]}<br />
                全部: ${e[0].data[1]}<br />
                <span style="color:#ff1a1a">离线: </span> ${e[0].data[2]}<br />
                <span style="color:#fe9400">漏油: </span>${e[0].data[3]}<br />
                <span style="color:#0079fe">超速: </span>${e[0].data[4]}<br />
              </div>
            `
          }
        }
      })
    },
    // 车辆报警 报警汇总列表 
    getCarAlarmList (params) {
      this.tableLoading = true
      this.$http.getCarAlarmList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataCarSummary = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 车辆报警 报警明细列表 
    getCarAlarmDetailList (params) {
      params.carId = this.carNumberId
      this.tableLoading = true
      this.$http.getCarAlarmDetailList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataCarDetail = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 报警列表点击切换
    onClickCarAlarmDetail (row) {
      this.carNumberId = row.carId
      this.carNumber = row.carName
      this.remoteCar = row.carName
      this.currentAlarmTabIndex = 1
    },
    // 设施报警数据
    getFacilitiesAlarmData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sheshiName: this.remoteFacl ? this.remoteFacl.split('[')[0] : '',  // 设施名称   
        alarmTypeName: this.alarmType == '全部' ? '' : this.alarmTypes, // 报警类型 
        facltypeid: this.facilityType == 0 ? '' : this.facilityType,//设施类型ID
        depId: this.departmentId
      }
      // 时间
      this.dateJudge(params, 'facl')
      this.getFacilitiesAlarmEchars(params)
      switch (this.currentAlarmTabIndex) {
        case 0:
          this.getFacilitiesAlarmList(params)
          break;
        case 1:
          this.getFacilitiesAlarmDetailList(params)
          break;
        default:
          break;
      }
    },
    // 设施报警 报警分析 echars 数据
    getFacilitiesAlarmEchars (params) {
      // 天1 月2
      params.dateType = this.selectType
      this.facilitiesAlarmEcharsData = []
      this.$http.getFacilitiesAlarmEchars(params).then(res => {
        if (res.code == 200) {
          this.facilitiesAlarmEcharsData = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
              <div >
                ${e[0].data[0]}<br />
                全部: ${e[0].data[1]}<br />
                <span style="color:#ff1a1a">氨气报警: </span> ${e[0].data[2]}<br />
                <span style="color:#fe9400">烟雾报警</span>: </span>${e[0].data[3]}<br />
                <span style="color:#0079fe">电耗报警: </span>${e[0].data[4]}<br />
                <span style="color:#25b111">硫化氢报警: </span>${e[0].data[5]}<br />
              </div>
            `
          }
        }
      })
    },
    // 设施报警 报警汇总列表 
    getFacilitiesAlarmList (params) {
      this.tableLoading = true
      this.$http.getFacilitiesAlarmList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataFacilitySummary = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 设施报警 报警明细列表 
    getFacilitiesAlarmDetailList (params) {
      params.sheshiId = this.facilityId
      this.tableLoading = true
      this.$http.getFacilitiesAlarmDetailList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataFacilityDetail = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    onClickFaclAlarmDetail (row) {
      this.facilityId = row.sheshiId
      this.facilityName = row.sheshiName
      this.remoteFacl = row.sheshiName
      this.currentAlarmTabIndex = 1
    },
    // 人员报警数据
    getPersonAlarmData () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personName: this.remoteName ? this.remoteName.split('[')[0] : '',  // 人员名称
        alarmTypeName: this.personAlarmType == '全部' ? '' : this.personAlarmType, // 报警类型 
        depId: this.departmentId
      }
      // 时间
      this.dateJudge(params, 'person')
      this.getPersonAlarmEchars(params)
      switch (this.currentAlarmTabIndex) {
        case 0:
          this.getPersonAlarmList(params)
          break;
        case 1:
          this.getPersonAlarmDetailList(params)
          break;
        default:
          break;
      }
    },
    // 人员报警 报警分析 echars 数据
    getPersonAlarmEchars (params) {
      // 天1 月2
      params.dateType = this.selectType
      this.$http.getPersonAlarmEchars(params).then(res => {
        if (res.code == 200) {
          this.personAlarmEcharsData = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
              <div >
                ${e[0].data[0]}<br />
                全部: ${e[0].data[1]}<br />
                <span style="color:#ff1a1a">离线: </span> ${e[0].data[2]}<br />
                <span style="color:#fe9400">SOS求救: </span> ${e[0].data[3]}<br />
              </div>
            `
          }
        }
      })
    },
    // 人员报警 报警汇总列表 
    getPersonAlarmList (params) {
      this.tableLoading = true
      this.$http.getPersonAlarmSummaryList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataPersonSummary = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 人员报警 报警明细列表 
    getPersonAlarmDetailList (params) {
      params.personId = this.nameId
      this.tableLoading = true
      this.$http.getPersonAlarmDetailList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataPersonDetail = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    onClickPersonAlarmDetail (row) {
      this.nameId = row.personId
      this.name = row.personName
      this.remoteName = row.personName
      this.currentAlarmTabIndex = 1
    },
    // 列表
    async listData (api, params, data) {
      let res = {}
      try {
        res = await this.$http[api](params)
        if (res.code == 200) {
          this[data] = res.result.rows
          this.totalPage = res.result.total
        }
      } catch (err) {
        console.log(err);
      }
      return res
    },
    // 按天or按月
    dateJudge (params, type) {
      if (this.date[0]) {
        params.alarmStartTime = this.date[0]
        params.alarmEndTime = this.date[1]
      }
      // let moment = require('moment');
      // let creatDate = moment().format('YYYY-MM-DD hh:mm:ss')
      // if (this.date.length > 0) {
      //   if (this.date[0] != '') {
      //     if (this.selectType == 1) {
      //       if (type == 'dept') {
      //         params.startDate = this.date[0] + ' 00:00:00'
      //         params.endDate = this.date[1] + ' 23:59:59'
      //       } else {
      //         params.alarmStartTime = this.date[0] + ' 00:00:00'
      //         params.alarmEndTime = this.date[1] + ' 23:59:59'
      //       }
      //     } else {
      //       params.alarmTime = this.date
      //     }
      //   } else {
      //     params.alarmTime = creatDate
      //   }
      // } else {
      //   params.alarmTime = creatDate
      // }
      return params
    },
    // 设施名称
    getFacilityList () {
      this.$http.getBindObjectTreeList({ treetype: 'facl' }).then(res => {
        if (res.code === 200) {
          this.facilityArr = res.result
        }
      })
    },
    // 设施类型
    getFacilitiesAlarmSelect () {
      this.$http.getFacilityBigTypeAllList({
        type: 'facl'
      }).then(res => {
        if (res.code === 200) {
          let arr = [{
            id: 0,
            name: '全部'
          }]
          this.facilityTypeArr = arr.concat(res.result)
        }
      })
    },
    // 设施大类回调 获取设施报警类型
    onChangeBigType (value) {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      let params = {
        page: 1,
        size: 99999,
        thresholdtype: 'facl'
      }
      if (value) {
        params.faclitypeid = value
      } else {
        params.faclitypeid = ''
      }
      this.$http.getAlarmList(params).then(res => {
        if (res.code === 200) {
          this.alarmTypeArrs = arr.concat(res.result.thresholdlist.map(item => {
            item.name = item.sysname
            return item
          }))
        }
      })
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 修改姓名
    onChangeName (section) {
      if (section.type === 'user') {
        this.nameId = section.id
        this.name = section.name
      }
      // let idArr = []
      // let valueArr = []
      // section.forEach(item => {
      //   if (item.type === 'user') {
      //     idArr.push(item.id)
      //     valueArr.push(item.name)
      //   }
      // })
      // this.nameId = idArr.join()
      // this.name = valueArr.join()
    },
    // 修改车牌号
    onChangeCarNumber (section) {
      if (section.type === 'car') {
        this.carNumberId = section.id
        this.carNumber = section.name
      }
      // let idArr = []
      // let valueArr = []
      // section.forEach(item => {
      //   if (item.type === 'car') {
      //     idArr.push(item.id)
      //     valueArr.push(item.name)
      //   }
      // })
      // this.carNumberId = idArr.join()
      // this.carNumber = valueArr.join()
    },
    // 修改设施名称
    onChangeFacility (section) {
      if (section.type !== 'depart') {
        this.facilityId = section.id
        this.facilityName = section.name
      }
      // let idArr = []
      // let valueArr = []
      // section.forEach(item => {
      //   if (item.type !== 'depart') {
      //     idArr.push(item.id)
      //     valueArr.push(item.name)
      //   }
      // })
      // this.facilityId = idArr.join()
      // this.facilityName = valueArr.join()
    },
    onChangeDate (date) {
      // this.date = date
      let millisecond = 1000 * 60 * 60 * 24
      let startDate = new Date(date[0]).getTime()
      let endDate = new Date(date[1]).getTime()
      if (endDate - startDate < millisecond * 2) {
        this.date = getCurrentMonth()
        this.$Message.info('最少选择三天')
      } else {
        this.date = date
      }
      this.buttonIndex = null
    },
    // 月份回调
    onChangeMonthrange (arr) {
      let startMonthArr = arr.month[0].split('-')
      let endMonthArr = arr.month[1].split('-')
      if (parseInt(endMonthArr[1]) - parseInt(startMonthArr[1]) >= 2) {
        this.date = arr.day
      } else {
        this.date = []
        this.dateMonthrange = []
        this.$Message.info('最少选择三个月')
      }
      this.buttonIndex = null
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.carName)
          break;
        case 1:
          this.selectArr = selection.map(item => item.sheshiId)
          break;
        case 2:
          this.selectArr = selection.map(item => item.personId)
          break;
        default:
          break;
      }
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.carViolationId || row.dlbjViolationId)) {
        return 'table-visited-bgcolor'
      }
      if (this.currentTabIndex === 0) {
        if (this.currentAlarmTabIndex === 0) {
          if (this.selectIndexArr.includes(row.carName)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentAlarmTabIndex === 1) {
          if (this.selectIndexArr.includes(row.alarmStartTime)) {
            return 'table-bgcolor'
          }
        }

      } if (this.currentTabIndex === 1 ){
        if (this.currentAlarmTabIndex === 0) {
          if (this.selectIndexArr.includes(row.sheshiId)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentAlarmTabIndex === 1) {
          if (this.selectIndexArr.includes(row.alarmStartTime)) {
            return 'table-bgcolor'
          }
        }

      } if (this.currentTabIndex === 2 ){
        if (this.currentAlarmTabIndex === 0) {
          if (this.selectIndexArr.includes(row.personId)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentAlarmTabIndex === 1) {
          if (this.selectIndexArr.includes(row.alarmStartTime)) {
            return 'table-bgcolor'
          }
        }

      }

    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay(false)
      this.getList()
      this.buttonIndex = 2
    },
    // 近七天
    onClickNearlySevenDay () {
      this.date = getNearlySevenDay(false)
      this.getList()
      this.buttonIndex = 0
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth(false)
      this.buttonIndex = 1
      let millisecond = 1000 * 60 * 60 * 24
      if (new Date(this.date[1]).getTime() - new Date(this.date[0]).getTime() < millisecond * 2) {
        this.$Message.info('最少选择三天')
        this.carAlarmEcharsData = []
        this.dataCarSummary = []
        this.facilitiesAlarmEcharsData = []
        this.dataFacilitySummary = []
        this.personAlarmEcharsData = []
        this.dataPersonSummary = []
        this.totalPage = 0
        return
      }
      this.getList()
    },
    // 近几个月
    onClickPreMonthDay (num) {
      this.date = getPreMonthDay(num)
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
      switch (num) {
        case 3:
          this.buttonIndex = 3
          break;
        case 6:
          this.buttonIndex = 4
          break;
        default:
          break;
      }
    },
    // 本年
    onClickCurrentYear () {
      this.date = getCurrentYearToNow()
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 重置按钮
    onResetSearchData () {
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // tabs 标签页切换
    onClickTabs (data) {
      this.selectArr = []
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 车牌号远程搜索
    remoteMethod: throttle(function (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = [];
          }
        })
      } else {
        this.remoteCarList = [];
      }
    }),
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 设施远程搜索
    remoteFaclMethod: throttle(function (query) {
      if (query !== '') {
        this.remoteFaclLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchFaclList(params).then((res) => {
          if (res.code === 200) {
            this.remoteFaclLoading = false
            let data = res.result
            this.remoteFaclList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteFaclLoading = false
            this.remoteFaclList = [];
          }
        })
      } else {
        this.remoteFaclList = [];
      }
    }),
    onQueryChangeRemoteFacl (value) {
      if (!value) {
        this.remoteFaclList = []
      }
    },
    // 人员远程搜索
    remoteNameMethod: throttle(function (query) {
      if (query !== '') {
        this.remoteNameLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchPersonList(params).then((res) => {
          if (res.code === 200) {
            this.remoteNameLoading = false
            let data = res.result
            this.remoteNameList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteNameLoading = false
            this.remoteNameList = [];
          }
        })
      } else {
        this.remoteNameList = [];
      }
    }),
    onQueryChangeRemoteName (value) {
      if (!value) {
        this.remoteNameList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.pageSize = 20
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        // case 0:
        //   this.getDeptAlarmData()
        //   break;
        case 0:
          this.getCarAlarmData()
          break;
        case 1:
          this.getFacilitiesAlarmData()
          break;
        case 2:
          this.getPersonAlarmData()
          break;
        default:
          break;
      }
    },
    // 重置搜索条件
    resetSearchData () {
      this.pageNum = 1
      this.pageSize = 20
      this.selectType = 1
      this.name = ''
      this.nameId = ''
      this.remoteName = ''
      this.facilityId = ''
      this.facilityName = ''
      this.remoteFacl = ''
      this.remoteCar = ''
      this.carNumber = ''
      this.carNumberId = ''
      this.alarmType = ''
      this.personAlarmType = ''
      this.facilityType = ''
      this.alarmTypes = ''
      this.departmentId = ''
      this.departmentType = ''
      this.departmentName = ''
      this.date = []
      this.dateMonth = ''
      if (this.$refs.formDate) {
        this.$refs.formDate.handleClear()
      }
      this.buttonIndex = null
      this.currentAlarmTabIndex = 0
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data.alarmTypeName = this.alarmType == '全部' ? '' : this.alarmType // 报警类型 
          this.dateJudge(data, 'car')
          if (value == 'now') {
            if (this.currentAlarmTabIndex == 0) {
              data.carNames = this.getData.map(item => {
                return item.carName
              })
            } else {
              data.carName = this.carNumber
              data.carId = this.carNumberId
              data.AlarmTimes = this.getData.map(item => {
                return item.alarmStartTime + ',' + item.alarmEndTime
              })
            }
          } else if (value == 'part') {
            if (this.currentAlarmTabIndex == 0) {
              data.carNames = this.selectArr.map(item => {
                return item.carName
              })
            } else {
              data.carName = this.carNumber
              data.carId = this.carNumberId
              data.AlarmTimes = this.selectArr.map(item => {
                return item.alarmStartTime + ',' + item.alarmEndTime
              })
            }
          } else {
            if (this.currentAlarmTabIndex == 1) {
              data.carName = this.carNumber
              data.carId = this.carNumberId
            }
          }
          break;
        case 1:
          data.sheshiName = this.remoteFacl ? this.remoteFacl.split('[')[0] : '' // 设施名称   
          data.alarmTypeName = this.alarmType == '全部' ? '' : this.alarmTypes // 报警类型 
          data.facltypeid = this.facilityType == 0 ? '' : this.facilityType//设施类型ID
          data.dateType = this.selectType
          this.dateJudge(data, 'car')
          if (value == 'now') {
            if (this.currentAlarmTabIndex == 0) {
              data.sheshiIds = this.getData.map(item => {
                return item.sheshiId
              })
            } else {
              data.ids = this.getData.map(item => {
                return item.id
              })
            }
          } else if (value == 'part') {
            if (this.currentAlarmTabIndex == 0) {
              data.sheshiIds = this.selectArr.map(item => {
                return item.sheshiId
              })
            } else {
              data.ids = this.selectArr.map(item => {
                return item.id
              })
            }
          } else {
            if (this.currentAlarmTabIndex == 1) {

            }
          }
          break;
        case 2:
          data.personName = this.remoteName ? this.remoteName.split('[')[0] : '' // 人员名称
          data.alarmTypeName = this.alarmType == '全部' ? '' : this.alarmType // 报警类型 
          data.dateType = this.selectType
          this.dateJudge(data, 'car')
          if (value == 'now') {
            if (this.currentAlarmTabIndex == 0) {
              data.personIds = this.getData.map(item => {
                return item.personId
              })
            } else {
              data.ids = this.getData.map(item => {
                return item.id
              })
            }
          } else if (value == 'part') {
            if (this.currentAlarmTabIndex == 0) {
              data.personIds = this.selectArr.map(item => {
                return item.personId
              })
            } else {
              data.ids = this.selectArr.map(item => {
                return item.id
              })
            }
          } else {
            if (this.currentAlarmTabIndex == 1) {

            }
          }
          break;
        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.noData {
  height: 400px;
  font-size: 16px;
  img {
    width: 150px;
    height: 100px;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }
  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;
      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;
        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }
      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>